import { Box, Breadcrumbs, Button } from "@infinitaslearning/pixel-design-system";
import { MAIN_ROUTES, SUB_ROUTES } from "@utils/router-helper";
import {
  getCurrentPageTitle,
  getMaxBreadcrumbs,
} from "@components/breadcrumb-bar/breadcrumb-helper";

import BreadcrumbLink from "@components/breadcrumb-bar/breadcrumb-link";
import type { FC } from "react";
import { isDummy } from "@utils/is-dummy";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useRouter } from "next/router";
import { useSelectedNavigation } from "@utils/use-selected-navigation";
import { useTranslation } from "next-i18next";
import useWindowSize from "@utils/use-window-size";
import { PepTypography } from "@components/pep-typography";

export const BAR_HEIGHT = 55;

export const BreadcrumbBar: FC = () => {
  const router = useRouter();
  const { t } = useTranslation("common", { keyPrefix: "breadcrumb-bar" });

  const subjectName: string | string[] | undefined = router.query.subject;
  const methodId: string | string[] | undefined = router.query.method;
  const methodGroupId: string | string[] | undefined = router.query["method-group"];
  const chapterId: string | string[] | undefined = router.query.chapter;
  const paragraphId: string | string[] | undefined = router.query.paragraph;

  const { isLoading, error, selected } = useSelectedNavigation();
  const showPupilsPlatformLink = useFeatureFlag("showPupilsPlatformLink", true);
  const { width } = useWindowSize();

  if (error) {
    //if this component crashes the main page will likely also crash, so we prevent double error pages.
    return <> </>;
  }

  const currentPage = getCurrentPageTitle(selected, t);

  // Create a list of possible breadcrumbs, then filter the uninitialized ones
  const breadcrumbLinks: { link: string; title: string | undefined }[] = [
    { link: `${MAIN_ROUTES.content}${SUB_ROUTES.contentOverview}`, title: t("overview") },
    {
      link: `${MAIN_ROUTES.content}/${subjectName}/${methodId}`,
      title: selected.selectedMethod?.name,
    },
    {
      link: `${MAIN_ROUTES.content}/${subjectName}/${methodId}/${methodGroupId}`,
      title: selected.selectedMethodGroup?.name,
    },
    {
      link: `${MAIN_ROUTES.content}/${subjectName}/${methodId}/${methodGroupId}/${chapterId}`,
      title: selected.selectedChapter?.name,
    },
    {
      link: `${MAIN_ROUTES.content}/${subjectName}/${methodId}/${methodGroupId}/${chapterId}/${paragraphId}`,
      title: selected.selectedParagraph?.name,
    },
  ].filter((link) => {
    return !!link.title && !isDummy(link.title);
  });

  return (
    <Box
      data-usetiful="breadcrumb"
      sx={{
        p: 1,
        ml: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Breadcrumbs
        data-usetiful="breadcrumb"
        sx={{ p: 1 }}
        separator="›"
        maxItems={getMaxBreadcrumbs(width, breadcrumbLinks.length)}
      >
        {!isLoading &&
          breadcrumbLinks
            .filter((link) => currentPage !== link.title)
            .map((link) => <BreadcrumbLink key={link.link} link={link} />)}
        {!isLoading && <PepTypography color="common.black">{currentPage}</PepTypography>}
      </Breadcrumbs>
      {showPupilsPlatformLink && (
        <Box>
          <Button
            // @ts-ignore This is allowed
            target="_blank"
            rel="noopener"
            href={t("pupilLinkHref")}
            variant="outlined"
            sx={{ whiteSpace: "nowrap", mr: 2 }}
          >
            <PepTypography variant="buttonSmall" t={t} translationKey="pupilLinkText" />
          </Button>
        </Box>
      )}
    </Box>
  );
};
