import { Button, Stack } from "@infinitaslearning/pixel-design-system";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useGetOpcoSpecificHomeButton } from "@components/platform-error/utils/useGetOpcoSpecificHomeButton";
import { PepTypography } from "@components/pep-typography";

const BTN_SIZE = "large";
const BTN_CLR = "primary";
const BTN_VAR = "contained";
const LBL_VAR = "buttonLarge";
const LBL_CLR = "common.white";

export const ReloadCTA = () => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const reload_key = "platform-error.reload-cta";
  return (
    <Button size={BTN_SIZE} color={BTN_CLR} variant={BTN_VAR} onClick={router.reload}>
      <PepTypography variant={LBL_VAR} color={LBL_CLR} t={t} translationKey={reload_key} />
    </Button>
  );
};

export const HomeCTA = () => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const homePageRoute = useGetOpcoSpecificHomeButton();

  const home_key = "platform-error.home-cta";
  return (
    <Button
      size={BTN_SIZE}
      color={BTN_CLR}
      variant={BTN_VAR}
      onClick={() => router.push(homePageRoute)}
    >
      <PepTypography variant={LBL_VAR} color={LBL_CLR} t={t} translationKey={home_key} />
    </Button>
  );
};

export const HomePlusReloadCTA = () => {
  return (
    <Stack gap={1} direction="row">
      <HomeCTA />
      <ReloadCTA />
    </Stack>
  );
};
