import type { SupportedLocale } from "@utils/use-brand";

const ERROR_MESSAGES = {
  nl: {
    title: "Oeps, er ging iets mis!",
  },
  "nl-be": {
    title: "Oeps, er ging iets mis!",
  },
  "fr-be": {
    title: "Erreur!",
  },
  sv: {
    title: "Någonting är fel",
  },
  en: {
    title: "Ooops, something went wrong",
  },
};

export const getGlobalErrorTitle = (locale: SupportedLocale) => {
  const validLocale = locale || "en";
  return ERROR_MESSAGES[validLocale as keyof typeof ERROR_MESSAGES].title;
};
