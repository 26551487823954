import { useMeQuery, useMeRefIdQuery, type MeQuery } from "@generated/graphql";
import { setAnalyticsTrackerUser } from "@lib/analytics-tracker";
import { getAllValues } from "@lib/configcat";
import { isUserInRole } from "@utils/role-helper";
import { useBrand } from "@utils/use-brand";
import { useRouter } from "next/router";
import { createContext, useEffect, useMemo, useState, type ReactNode } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "next-i18next";
import { useClassrooms } from "@utils/use-classrooms";
import type { QueryObserverResult } from "@tanstack/react-query";

export const FEATURE_TOGGLES_COOKIE = "feature-toggles";
export const FEATURE_TOGGLES_COOKIE_MAX_AGE = 31556952; // one year

export type FeatureFlag = {
  key: string;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  value: any;
  isToggleable: boolean;
  isGammaWaveFeature: boolean;
};

export type UserContextType = {
  me: {
    isLoading: boolean;
    data: MeQuery["me"] | undefined;
    refOrganizationId: string | null | undefined;
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    error: any;
    refetchMeData: () => Promise<QueryObserverResult<MeQuery, unknown>>;
  };
  features: FeatureFlag[] | undefined;
  setFeatures: (features: FeatureFlag[] | undefined) => void;
  roles: {
    isPublisher: boolean;
    isAdmin: boolean;
    isTeacher: boolean;
  };
};

const UserContext = createContext<UserContextType | undefined>(undefined);

const UserProvider = ({ children }: { children: ReactNode | ReactNode[] }): JSX.Element => {
  const { opCo, locale, analyticsLocale } = useBrand();
  const [featuresData, setFeaturesData] = useState<FeatureFlag[] | undefined>(undefined);
  const { t: configTranslation } = useTranslation("common", {
    keyPrefix: "config-toggles",
  });
  const [cookies] = useCookies([FEATURE_TOGGLES_COOKIE]);
  const {
    data,
    isInitialLoading: isLoadingMeData,
    error: errorMeData,
    refetch: refetchMeData,
  } = useMeQuery();
  const {
    data: extraData,
    isInitialLoading: isLoadingMeExtraData,
    error: errorMeExtraData,
  } = useMeRefIdQuery(undefined, { enabled: opCo === "liber" });

  const router = useRouter();
  const meData = data?.me;
  const { data: classroomsData, isLoading: isLoadingClassrooms } = useClassrooms({
    staleTime: Number.POSITIVE_INFINITY,
  });
  const classroomIds =
    classroomsData?.classroomsOfUser.map((data) => {
      return data.id;
    }) ?? [];

  useEffect(() => {
    async function fetchFeatures() {
      const features = await getAllValues(
        opCo,
        meData,
        { locale, targetEan: router.query.targetEAN },
        configTranslation,
        cookies[FEATURE_TOGGLES_COOKIE],
      );
      setFeaturesData(features);
    }
    if (meData && !featuresData) fetchFeatures();
  }, [featuresData, meData, opCo, locale, configTranslation, cookies, router.query.targetEAN]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: it's missing here for a reason
  useEffect(() => {
    if (meData && !isLoadingClassrooms) {
      setAnalyticsTrackerUser(
        meData.id,
        meData.organizationId || "",
        meData.roles,
        analyticsLocale,
        classroomIds,
      );
    }
  }, [meData?.id]);

  const value = useMemo(
    () => ({
      me: {
        isLoading: isLoadingMeData || isLoadingMeExtraData,
        data: meData,
        refOrganizationId: extraData?.me?.refOrganizationId ?? "",
        error: errorMeData || errorMeExtraData,
        refetchMeData,
      },
      features: featuresData,
      setFeatures: setFeaturesData,
      roles: {
        isPublisher: isUserInRole("publisher", meData?.roles),
        isAdmin: isUserInRole("ict-coordinator", meData?.roles),
        isTeacher: isUserInRole("teacher", meData?.roles),
      },
    }),
    [
      errorMeData,
      meData,
      isLoadingMeData,
      errorMeExtraData,
      isLoadingMeExtraData,
      featuresData,
      extraData?.me?.refOrganizationId,
      refetchMeData,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
