import { Button, Paper, Stack, type Theme } from "@infinitaslearning/pixel-design-system";
import { useTranslation } from "next-i18next";
import ArrowRightLongIcon from "@infinitaslearning/pixel-icons/ArrowRightLong";
import { useGammaWave } from "./helpers/useGammaWave.hook";
import { trackInsiderElementClicked } from "@pages/insider/insider-page-utils";
import { PepTypography } from "@components/pep-typography";

const colorLight = (theme: Theme) =>
  theme.pixel.color.palette.primary[90] ?? theme.palette.primary.light;
const colorDark = (theme: Theme) =>
  theme.pixel.color.palette.primary[10] ?? theme.palette.primary.dark;

export const InsiderProgramCTA = () => {
  const { t } = useTranslation("common", { keyPrefix: "user-sidebar" });
  const { isGammaWaveEnabled, isUserInGammaRole } = useGammaWave();

  if (isUserInGammaRole || !isGammaWaveEnabled) {
    return null;
  }

  return (
    <Paper
      sx={(theme) => ({
        my: 2,
        backgroundColor: colorLight(theme),
        color: colorDark(theme),
      })}
      elevation={3}
    >
      <Stack p={2} gap={1}>
        <PepTypography variant="subtitle1Bold" t={t} translationKey="gamma-cta-title" />
        <PepTypography t={t} translationKey="gamma-cta-desc" />
        <Button
          href="/insider"
          size="small"
          endIcon={<ArrowRightLongIcon />}
          onClick={() => {
            trackInsiderElementClicked(
              "insder-program-sidebar-options",
              "button",
              "more-info-button",
            );
          }}
        >
          <PepTypography t={t} translationKey="gamma-cta" />
        </Button>
      </Stack>
    </Paper>
  );
};
