import BrandLogo, { type BrandLogoProps, LABEL_MAP } from "@components/logos/brand-logo";
import { PepTypography } from "@components/pep-typography";
import { Stack, type TypographyProps, useTheme } from "@infinitaslearning/pixel-design-system";
import { useBrand } from "@utils/use-brand";
import { memo } from "react";

const RENDER_MAP = {
  noordhoff: false,
  plantyn: true,
  liber: false,
};

type BrandLogoWithTitleProps = BrandLogoProps & {
  titleProps?: TypographyProps;
};

const BrandLogoWithTitle = ({ variant = "block", titleProps }: BrandLogoWithTitleProps) => {
  const { opCo } = useBrand();
  const theme = useTheme();

  return (
    <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
      <BrandLogo variant={variant} />
      {RENDER_MAP[opCo] && (
        <PepTypography
          variant="h2"
          color={theme.pixel.cta.contained.color.text.primary.default}
          {...titleProps}
        >
          {LABEL_MAP[opCo]}
        </PepTypography>
      )}
    </Stack>
  );
};

export default memo(BrandLogoWithTitle);
