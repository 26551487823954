import { PepTypography } from "@components/pep-typography";
import PlatformErrorImage from "@components/platform-error/platform-error-image";
import {
  CENTERED_SX,
  type ErrorComponentProps,
} from "@components/platform-error/platform-error-types";
import {
  getErrorMessageTSX,
  getGradientBackground,
} from "@components/platform-error/platform-error-utils";
import { Divider, Stack, type Theme, alpha } from "@infinitaslearning/pixel-design-system";

const NO_BACKGROUND_SX = {
  background: "transparent",
  boxShadow: "none",
};

const RESPONSIVE_SX = {
  gap: 1,
  padding: 2,
  borderRadius: 4,
  height: "95%",
  width: "95%",
  boxShadow: (theme: Theme) =>
    `0 0 ${theme.spacing(2)} ${alpha(theme.pixel.color.palette.neutrals[0], 0.75)}`,
  backgroundImage: (theme: Theme) => getGradientBackground(theme),
  overflow: "auto",
  ...CENTERED_SX,
};

const RESPONSIVE_FULL = {
  ...RESPONSIVE_SX,
  height: "100%",
  width: "100%",
  padding: 4,
  borderRadius: 0,
  boxShadow: "",
};

export type ResponsiveErrorProps = ErrorComponentProps & {
  useFull: boolean;
  noBackground: boolean;
};

export const ResponsiveError: React.FC<ResponsiveErrorProps> = ({
  errorType,
  title,
  t,
  errorCode,
  customMessage,
  message,
  children,
  CTA,
  useFull,
  noBackground,
  sx,
}) => {
  const SX = useFull
    ? {
        ...RESPONSIVE_FULL,
        ...(noBackground ? NO_BACKGROUND_SX : {}),
        ...(sx ?? {}),
      }
    : {
        ...RESPONSIVE_SX,
        ...(noBackground ? NO_BACKGROUND_SX : {}),
        ...(sx ?? {}),
      };

  return (
    <>
      <Stack>{children}</Stack>
      <Stack sx={SX}>
        <Stack
          flexDirection="column"
          sx={{
            ...CENTERED_SX,
            gap: 3,
          }}
        >
          <PlatformErrorImage
            errorType={errorType}
            displayType={useFull ? "responsive_full" : "responsive"}
          />
          <PepTypography variant="h3">{title}</PepTypography>
        </Stack>
        <Divider orientation="horizontal" sx={{ my: 1 }} flexItem />
        {getErrorMessageTSX(t, errorCode, customMessage, message)}
        <Divider orientation="horizontal" sx={{ my: 1 }} flexItem />
        <Stack>{CTA}</Stack>
      </Stack>
    </>
  );
};
