import { useToursModalOpen } from "@components/help-navigation/use-tours-modal-open";
import ArrowsRotateIcon from "@infinitaslearning/pixel-icons/ArrowsRotate";
import CircleQuestionIcon from "@infinitaslearning/pixel-icons/CircleQuestion";
import WandMagicSparklesIcon from "@infinitaslearning/pixel-icons/WandMagicSparkles";
import GraduationCapIcon from "@infinitaslearning/pixel-icons/GraduationCap";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useTranslation } from "next-i18next";

export type HelpNavMenuItem = {
  name: string;
  href?: string;
  handleClick?: () => void;
  icon: JSX.Element;
  isHiddenInclass: boolean;
  isOpenNewTab?: boolean;
};

export const useHelpNavMenuItems = (): { [key: string]: HelpNavMenuItem } => {
  const { t } = useTranslation("common", { keyPrefix: "user-sidebar" });
  const { t: tHelpNav } = useTranslation("common", { keyPrefix: "help-navigation" });

  const { setIsToursModalOpen } = useToursModalOpen((state) => ({
    setIsToursModalOpen: state.setIsToursModalOpen,
  }));

  const trackElementClick = (elementId: string) => {
    getAnalyticsTracker()?.elementClicked({
      elementId,
      type: "button",
      module: "help-navigation",
      itemId: elementId,
    });
  };

  return {
    news: {
      name: tHelpNav("news"),
      href: "/news",
      handleClick: () => {
        trackElementClick("news-button");
      },
      icon: <WandMagicSparklesIcon />,
      isHiddenInclass: true,
    },
    help: {
      name: tHelpNav("help"),
      href: t("help-link"),
      handleClick: () => {
        trackElementClick("help-button");
      },
      icon: <CircleQuestionIcon />,
      isHiddenInclass: false,
      isOpenNewTab: true,
    },
    tour: {
      name: tHelpNav("tour"),
      handleClick: () => {
        setIsToursModalOpen(true);
        trackElementClick("usetiful-tour-button");
      },
      icon: <ArrowsRotateIcon />,
      isHiddenInclass: false,
    },
    academy: {
      name: tHelpNav("academy"),
      href: tHelpNav("academy-link"),
      handleClick: () => {
        trackElementClick("academy-button");
      },
      icon: <GraduationCapIcon />,
      isHiddenInclass: false,
      isOpenNewTab: true,
    },
  };
};
