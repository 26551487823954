import { useMeQuery } from "@generated/graphql";
import { ListItemIcon, ListItemText, MenuItem } from "@infinitaslearning/pixel-design-system";
import MessagesIcon from "@infinitaslearning/pixel-icons/Messages";
import { useSurvey } from "@infinitaslearning/survey";
import { useBrand } from "@utils/use-brand";
import { useTranslation } from "next-i18next";

const TALLY_FORM_ID_FR_BE_LOCALE = "me2xro";
const TALLY_FORM_ID_NL_BE_LOCALE = "wzZAE1";

export const TallyFeedbackButton = () => {
  const { t } = useTranslation("common", { keyPrefix: "help-navigation" });
  const { data } = useMeQuery();
  const meData = data?.me;

  const isFrenchLocale = useBrand().locale === "fr-be";
  const tallyFormId = isFrenchLocale ? TALLY_FORM_ID_FR_BE_LOCALE : TALLY_FORM_ID_NL_BE_LOCALE;
  const { open } = useSurvey({
    surveys: [
      {
        formId: tallyFormId,
        type: "REGULAR",
      },
    ],
    platform: "PEP",
    autoOpen: false,
    userId: meData?.id ?? "-",
  });

  return (
    <MenuItem onClick={() => open()}>
      <ListItemIcon>
        <MessagesIcon />
      </ListItemIcon>
      <ListItemText>{t("feedback")}</ListItemText>
    </MenuItem>
  );
};
