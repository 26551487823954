import React from "react";
import { useMeQuery } from "@generated/graphql";
import { Button } from "@infinitaslearning/pixel-design-system";
import MessagesIcon from "@infinitaslearning/pixel-icons/Messages";
import { useTranslation } from "next-i18next";
import { useSurvey } from "@infinitaslearning/survey";
import { useKeyboardBinding } from "@utils/persisted-values/use-keyboard-binding";
import { trackInsiderElementClicked } from "@pages/insider/insider-page-utils";
import { PepTypography } from "@components/pep-typography";

const TALLY_FORM_ID = "31vLGl";
const TALLY_MODAL_WIDTH = 500;

export const FeatureFeedbackButton: React.FC<{ featureId: string }> = ({ featureId }) => {
  const { t } = useTranslation("common", { keyPrefix: "gamma" });
  const { data } = useMeQuery();
  const meData = data?.me;

  const onAnswered = () => {
    trackInsiderElementClicked(
      "gamma-wave-feedback",
      "button",
      "feedback-submit-button",
      `featureId-${featureId}`,
    );
  };

  const { open, close, isLoaded, isOpen } = useSurvey({
    surveys: [
      {
        formId: TALLY_FORM_ID,
        type: "REGULAR",
      },
    ],
    platform: "PEP",
    userId: meData?.id ?? "-",
    popupOptions: {
      layout: "modal",
      width: TALLY_MODAL_WIDTH,
      hiddenFields: {
        userId: meData?.id,
        featureFlagId: featureId,
      },
    },
    autoOpen: false,
    onAnswered: onAnswered,
  });
  useKeyboardBinding({
    key: "Escape",
    onPress: () => {
      close();
    },
  });

  return (
    <Button
      onClick={() => {
        trackInsiderElementClicked(
          "gamma-wave-feedback",
          "button",
          "feedback-open-button",
          `featureId-${featureId}`,
        );
        open();
      }}
      disabled={!isLoaded || isOpen}
      startIcon={<MessagesIcon />}
    >
      <PepTypography t={t} translationKey="feedback" />
    </Button>
  );
};
