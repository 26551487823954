import type { SelectedNavigation } from "@utils/use-selected-navigation";
import type { TFunction } from "next-i18next";
import { isDummy } from "@utils/is-dummy";

const BREAKPOINT_SMALL = 700;
const BREAKPOINT_MEDIUM = 900;
const BREAKPOINT_LARGE = 1000;

export const getMaxBreadcrumbs = (width: number, breadcrumbLength: number) => {
  if (width <= BREAKPOINT_SMALL) return 2;
  if (width <= BREAKPOINT_MEDIUM) return 3;
  if (width <= BREAKPOINT_LARGE) return 4;

  return breadcrumbLength + 1;
};

const extractName = (name: string): string | undefined => {
  return !isDummy(name) ? name : undefined;
};
export const getCurrentPageTitle = (
  selectedNavigation: SelectedNavigation,
  t: TFunction,
): string => {
  const {
    selectedSubject,
    selectedMethod,
    selectedMethodGroup,
    selectedChapter,
    selectedParagraph,
    selectedLearningUnit,
  } = selectedNavigation;

  const learningUnitName = selectedLearningUnit?.name;
  const paragraphName = extractName(selectedParagraph?.name ?? "");
  const chapterName = extractName(selectedChapter?.name ?? "");
  const methodGroupName = selectedMethodGroup?.name;

  if (!selectedSubject) {
    return t("subject");
  }
  if (!selectedMethod) {
    return t("method");
  }
  if (!selectedMethodGroup) {
    return selectedMethod?.name || t("method-group");
  }
  if (!selectedChapter) {
    return methodGroupName || t("items");
  }
  if (!selectedParagraph) {
    return chapterName || methodGroupName || t("items");
  }
  if (!selectedLearningUnit) {
    return paragraphName || chapterName || methodGroupName || t("items");
  }
  return learningUnitName || t("learning-unit");
};
