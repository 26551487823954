import {
  FEATURE_TOGGLES_COOKIE,
  FEATURE_TOGGLES_COOKIE_MAX_AGE,
  UserContext,
} from "@components/user-context/user-context";
import { List } from "@infinitaslearning/pixel-design-system";

import FeatureFlagToggle from "./feature-flag-toggle";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { getFeatureList } from "./helpers/getFeatureList";
import { useContext } from "react";
import { useCookies } from "react-cookie";
import { useGammaWave } from "./helpers/useGammaWave.hook";
import { PepTypography } from "@components/pep-typography";
import { useTranslation } from "next-i18next";

type FeatureFlagSelectorProps = {
  wide?: boolean;
  showGammaOnly?: boolean;
};

export const FeatureFlagSelector = ({
  showGammaOnly = false,
  wide = false,
}: FeatureFlagSelectorProps) => {
  const { t } = useTranslation("common", { keyPrefix: "gamma" });
  const userContext = useContext(UserContext);
  const [cookies, setCookie] = useCookies([FEATURE_TOGGLES_COOKIE]);
  const features = getFeatureList(userContext).filter((feature) =>
    showGammaOnly ? feature.isGammaWaveFeature : true,
  );
  const { isGammaWaveEnabled, isUserInGammaRole } = useGammaWave();

  if (features.length === 0) {
    return <PepTypography textAlign="center" t={t} translationKey="no-features" />;
  }

  const toggle = (key: string) => {
    const selectedFeature = features.find((x) => x.key === key);
    const newValue = !selectedFeature?.value;
    const featureFlagCookie = cookies[FEATURE_TOGGLES_COOKIE] ?? [];

    const updatedFeatureFlagCookie = featureFlagCookie.filter(
      (keyValue: { key: string; value: string }) =>
        keyValue.key !== key && features.find((x) => x.key === keyValue.key),
    );
    updatedFeatureFlagCookie.push({ key: key, value: newValue });
    setCookie(FEATURE_TOGGLES_COOKIE, updatedFeatureFlagCookie, {
      maxAge: FEATURE_TOGGLES_COOKIE_MAX_AGE,
      path: "/",
      sameSite: "strict",
    });
    if (selectedFeature?.isGammaWaveFeature) {
      getAnalyticsTracker()?.elementClicked({
        elementId: "gamma-wave-toggle",
        type: "toggle",
        value: newValue ? "on" : "off",
        itemId: key,
      });
    }

    if (userContext) {
      const newFeatures = userContext.features?.map((featureFlag) => {
        return featureFlag.key === key
          ? {
              key: key,
              value: newValue,
              isToggleable: true,
              isGammaWaveFeature: featureFlag.isGammaWaveFeature,
            }
          : featureFlag;
      });

      userContext.setFeatures(newFeatures);
    }
  };

  return (
    <List sx={{ mt: 2, mb: 2, overflow: "auto" }}>
      {features.map((feature) => (
        <FeatureFlagToggle
          key={feature.key}
          id={feature.key}
          value={feature.value}
          toggle={toggle}
          wide={wide}
          showFeedback={isGammaWaveEnabled && isUserInGammaRole && feature.isGammaWaveFeature}
        />
      ))}
    </List>
  );
};
