import { useState, useEffect } from "react";

export const ABORT_ROUTE_ERROR = "Abort route change";

const VALID_ERROR_MESSAGES = [ABORT_ROUTE_ERROR];

const isValidError = (error: Error | undefined) => {
  return VALID_ERROR_MESSAGES.includes(String(error));
};

const useGlobalErrorHandler = () => {
  const [globalError, setGlobalError] = useState<Error | undefined>(undefined);
  const [hasGlobalError, setHasGlobalError] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onerror = (_message, _source, _lineno, _colno, error) => {
        setGlobalError(error);
        setHasGlobalError(!isValidError(error));
      };

      window.onunhandledrejection = (event) => {
        setGlobalError(event.reason);
        setHasGlobalError(!isValidError(event.reason));
      };
    }

    return () => {
      window.onerror = null;
      window.onunhandledrejection = null;
    };
  }, []);

  return { globalError, hasGlobalError };
};

export default useGlobalErrorHandler;
