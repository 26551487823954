import { Box } from "@infinitaslearning/pixel-design-system";
import { signOut, useSession } from "next-auth/react";
import { useEffect, useState } from "react";

import ArrowRightFromBracketIcon from "@infinitaslearning/pixel-icons/ArrowRightFromBracket";
import CircleUserIcon from "@infinitaslearning/pixel-icons/CircleUser";
import Link from "@components/styled-link/styled-link";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { getSignOutUrl } from "@utils/idp-helpers";
import { useCookies } from "react-cookie";
import { useGammaWave } from "./helpers/useGammaWave.hook";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import GridTwoThreeIcon from "@infinitaslearning/pixel-icons/Grid2ThreeQuarters";
import { PepTypography } from "@components/pep-typography";

const linkVariant = "body1Bold";
const ID_TOKEN_COOKIE_NAME = "id_token";
const SIGNED_IN_COOKIE_NAME = "signed_in";

const ICON_SX = { mr: 1 };
const LINK_SX = { cursor: "pointer", display: "flex", alignItems: "center" };

export const AccountLinks = () => {
  const { t } = useTranslation("common", { keyPrefix: "user-sidebar" });
  const router = useRouter();
  const { data: session } = useSession();
  const [_, __, removeCookie] = useCookies([ID_TOKEN_COOKIE_NAME, SIGNED_IN_COOKIE_NAME]);
  const [signOutUrl, setSignOutUrl] = useState<string>("");
  const { isGammaWaveEnabled } = useGammaWave();

  useEffect(() => {
    if (session) {
      getSignOutUrl(window.location.origin, session).then((url) => {
        if (url) {
          setSignOutUrl(url);
        }
      });
    }
  }, [session]);

  const handleSignOut = async () => {
    removeCookie(ID_TOKEN_COOKIE_NAME);
    removeCookie(SIGNED_IN_COOKIE_NAME);
    // we always need to make sure we clear local cookies before redirecting to the idp
    await signOut({ redirect: false });
    return router.push(signOutUrl);
  };

  const trackElementClick = (elementId: string) => {
    getAnalyticsTracker()?.elementClicked({
      elementId,
      type: "link",
      module: "user-sidebar",
      itemId: elementId,
    });
  };

  const links = [
    {
      id: "user-details",
      Icon: CircleUserIcon,
      href: "user-details-link",
      show: true,
    },
    {
      id: "gamma",
      Icon: GridTwoThreeIcon,
      path: "/insider",
      show: isGammaWaveEnabled,
    },
    {
      id: "logout",
      Icon: ArrowRightFromBracketIcon,
      onClick: handleSignOut,
      show: true,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {links
        .filter((link) => !!link.show)
        .map(({ id, Icon, href, path, onClick }) => (
          <Link
            href={href ? t(href) : { pathname: path, query: {} }}
            onClick={onClick ? onClick : undefined}
            rel={href ? "noreferrer" : undefined}
            target={href ? "_blank" : undefined}
            sx={LINK_SX}
            key={id}
          >
            <Icon sx={ICON_SX} color="primary" />
            <PepTypography
              onClick={() => {
                trackElementClick("user-details-link");
              }}
              variant={linkVariant}
              component="span"
            >
              {t(id)}
            </PepTypography>
          </Link>
        ))}
    </Box>
  );
};
