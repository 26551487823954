import { PepTypography } from "@components/pep-typography";
import PlatformErrorImage from "@components/platform-error/platform-error-image";
import {
  CENTERED_SX,
  type ErrorComponentProps,
} from "@components/platform-error/platform-error-types";
import {
  getErrorMessageTSX,
  getGradientBackground,
} from "@components/platform-error/platform-error-utils";
import { Stack, type Theme } from "@infinitaslearning/pixel-design-system";

const FULL_SCREEN_SX = {
  gap: 1,
  height: "100vh",
  width: "100vw",
  backgroundImage: (theme: Theme) => getGradientBackground(theme),
  ...CENTERED_SX,
};

export const FullScreenError: React.FC<ErrorComponentProps> = ({
  errorType,
  t,
  errorCode,
  customMessage,
  message,
  title,
  children,
  CTA,
  sx,
}) => {
  const SX = { ...FULL_SCREEN_SX, ...(sx ?? {}) };

  return (
    <Stack sx={SX}>
      <PlatformErrorImage errorType={errorType} displayType={"full"} />
      <PepTypography variant="h1">{title}</PepTypography>
      {getErrorMessageTSX(t, errorCode, customMessage, message)}
      <Stack>{children}</Stack>
      <Stack>{CTA}</Stack>
    </Stack>
  );
};
